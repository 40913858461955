import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { errorColor } from "services/colors/constants";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const EditMenuModal = ({ handleCloseModal, showModal, refetch, modalData }) => {
  // console.log(modalData);
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const progress = new ProgressBar({ color: errorColor, size: 4 });
  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: modalData.title,
    },
  });
  useEffect(() => {
    if (modalData) {
      reset({
        title: modalData.title,
      });
    }
  }, [modalData, reset]);
  const onSubmit = async (data) => {
    data.menuId = modalData.id;
    try {
      progress.start();
      setDisabled(true);

      // Make the HTTP request using axios
      const response = await axiosPrivate.put(
        "/admin/menus/menu-categories",
        data
      );

      // Success handling
      toast.success(response?.data?.messages);
      progress.finish();
      handleCloseModal();
      setDisabled(false);
      reset(); 
      refetch();
    } catch (error) {
      progress.finish();
      setDisabled(false);
      console.log(error);
      toast.error("An error occurred");
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h6>Edit Menu Category</h6>
          <button
            onClick={handleCloseModal}
            className="btn-close btn-danger btn"
          ></button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                id="title"
                name="title"
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <div className="invalid-feedback">{errors.title.message}</div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleCloseModal}
              type="button"
              className="btn btn-secondary text-small"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={disabled}
              className="btn btn-primary text-small"
            >
              {!disabled && "Update Menu Category"}
              {disabled && (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Please Wait Updating ...
                </>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditMenuModal;

import toast from "react-hot-toast";
import { useState } from "react";
import axios from "config/axios";
import { useForm } from "react-hook-form";
import { errorColor } from "services/colors/constants";
import { useNavigate, useLocation } from "react-router-dom";
import useAuth from "middlewares/hooks/useAuth";
import ProgressBar from "@badrap/bar-of-progress";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useAuth();
  const [disabled, setDisabled] = useState(false);
  const from = location.state?.from?.pathname || "/dashboard";
  const progress = new ProgressBar({ color: errorColor, size: 4 });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onLoginAction = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axios.post("/admin/sessions", data);

      const accesstoken = response?.data?.data?.access_token;
      const refreshtoken = response?.data?.data?.refresh_token;
      const sessionid = response?.data?.data?.session_id;
      setAuth({ sessionid, accesstoken, refreshtoken });
      const verify = {
        refresh: refreshtoken,
        id: sessionid,
        access: accesstoken,
      };
      localStorage["verify"] = JSON.stringify(verify);
      navigate(from, { replace: true });
      progress.finish();
    } catch (error) {
      if (!error?.response) {
        setDisabled(false);
        toast.error("No server response", {
          style: { background: errorColor, color: "#000" },
        });
        progress.finish();
      } else {
        setDisabled(false);
        progress.finish();
        toast.error(error?.response?.data?.messages, {
          style: { background: errorColor, color: "#000" },
        });
      }
    }
  };
  return (
    <>
      <div className="wrapper">
        <div className="auth-content">
          <div className="card">
            <div className="card-body text-center">
              <div className="mb-4">
                <img
                  className="v-10"
                  height={"40px"}
                  src="logo.png"
                  alt="noImage"
                />
              </div>
              <h6 className="mb-4">Admin Login</h6>
              <form autoComplete="off" onSubmit={handleSubmit(onLoginAction)}>
                <div className="mb-3 text-start">
                  <label htmlFor="email" className="form-label">
                    Email adress
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Enter Email"
                    {...register("username", {
                      required: true,
                      pattern: { value: /^\S+@\S+$/i },
                    })}
                  />
                  {errors?.username?.type === "required" && (
                    <p className="text-small  m-0 text-danger help-block">
                      Please provide a valid username
                    </p>
                  )}
                </div>
                <div className="mb-3 text-start">
                  <label htmlFor="password" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    autoComplete="current-password"
                    {...register("password", { required: true })}
                  />
                  {errors?.password?.type === "required" && (
                    <p className="text-small m-0 text-danger help-block">
                      Please provide a password
                    </p>
                  )}
                </div>

                <button
                  className="btn btn-primary shadow-2 mb-4 w-100"
                  disabled={disabled}
                >
                  {!disabled && "Login"}{" "}
                  {disabled && (
                    <>
                      {" "}
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>{" "}
                      Please Wait Authorizing ...
                    </>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

import { keepPreviousData, useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
const Dashboard = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const controller = new AbortController();
  const { data } = useQuery({
    queryKey: ["dashbaord"],
    queryFn: async () => {
      try {
        const response = await axiosPrivate.get("/admin/dashboard", {
          signal: controller.signal,
        });
        return response.data.data.data;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
      }
    },
    placeholderData: keepPreviousData,
  });
  return (
    <>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 page-header">
              <div className="page-pretitle">Overview</div>
              <h2 className="page-title">Dashboard</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3 mt-3">
              <div className="card">
                <div className="content">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="icon-big text-center">
                        <i className="text-primary fas fa-shopping-cart"></i>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="detail">
                        <p className="detail-subtitle">Pending Orders</p>
                        <span className="number">{data?.pending}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 mt-3">
              <div className="card">
                <div className="content">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="icon-big text-center">
                        <i className="text-secondary fas fa-spinner"></i>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="detail">
                        <p className="detail-subtitle">Processing Orders</p>
                        <span className="number">{data?.processing}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 mt-3">
              <div className="card">
                <div className="content">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="icon-big text-center">
                        <i className="text-info fas fa-shipping-fast"></i>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="detail">
                        <p className="detail-subtitle">On Delivery</p>
                        <span className="number">{data?.delivery}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3 mt-3">
              <div className="card">
                <div className="content">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="icon-big text-center">
                        <i className="text-success fas fa-truck"></i>
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="detail">
                        <p className="detail-subtitle">Delivered</p>
                        <span className="number">{data?.delivered}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <div className="card">
                    <div className="content">
                      <div className="head">
                        <h5 className="mb-0">Clients Overview</h5>
                        <p className="text-muted">Current website data</p>
                      </div>
                      <div className="canvas-wrapper">
                        <canvas className="chart" id="trafficflow"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="content">
                      <div className="head">
                        <h5 className="mb-0">Orders Overview</h5>
                        <p className="text-muted">Current Orders data</p>
                      </div>
                      <div className="canvas-wrapper">
                        <canvas className="chart" id="sales"></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="card">
                <div className="content">
                  <div className="row">
                    <div className="dfd text-center">
                      <i className="blue large-icon mb-2 fas fa-users"></i>
                      <h4 className="mb-0">{data?.users}</h4>
                      <p className="text-muted">Clients</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="card">
                <div className="content">
                  <div className="row">
                    <div className="dfd text-center">
                      <i className="orange large-icon mb-2 fas fa-user"></i>
                      <h4 className="mb-0">1</h4>
                      <p className="text-muted">Users</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="card">
                <div className="content">
                  <div className="row">
                    <div className="dfd text-center">
                      <i className="grey large-icon mb-2 fas fa-envelope"></i>
                      <h4 className="mb-0">0</h4>
                      <p className="text-muted">NewsLetter Subs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="card">
                <div className="content">
                  <div className="row">
                    <div className="dfd text-center">
                      <i className="olive large-icon mb-2 fas fa-bacon"></i>
                      <h4 className="mb-0">{data?.items}</h4>
                      <p className="text-muted">Products</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { errorColor } from "services/colors/constants";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const AddProduct = ({ handleCloseModal, showModal, refetch }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const progress = new ProgressBar({ color: errorColor, size: 4 });
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const controller = new AbortController();
  const getCategories = async () => {
    try {
      const response = await axiosPrivate.get(
        "/admin/menus/sub-menu-categories",
        {
          signal: controller.signal,
        }
      );
      setData(response.data.data.menu);
    } catch (error) {
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (formData) => {
    try {
      progress.start();
      setDisabled(true);

      // Initialize FormData object
      const submissionData = new FormData();
      const dataObject = {
        title: formData.title,
        categoryId: formData.category,
        price: formData.price,
        description: formData.description,
      };
      submissionData.append("data", JSON.stringify(dataObject));
      // Append each form value to the FormData object
      for (const [key, value] of Object.entries(formData)) {
        // For file inputs, you need to append the file object itself
        if (key === "imagefile") {
          // Assuming here that register name and file input name is 'imagefile' and it holds the file
          submissionData.append(key, value[0]); // Since it's a file, access the first item
        } else {
          submissionData.append(key, value);
        }
      }

      // Make the HTTP request using axios
      const response = await axiosPrivate.post(
        "/admin/items/items",
        submissionData,
        {
          headers: {
            // Indicate that the request body is FormData
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Success handling
      toast.success(response?.data?.messages);
      progress.finish();
      handleCloseModal();
      setDisabled(false);
      reset(); // Reset the form using react-hook-form's reset method
      refetch(); // Assuming you want to refetch some data after submission
    } catch (error) {
      progress.finish();
      setDisabled(false);
      console.log(error);
      toast.error("An error occurred");
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h6>Add Menu Item</h6>
          <button
            onClick={handleCloseModal}
            className="btn-close btn-danger btn"
          ></button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                id="title"
                name="title"
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <div className="invalid-feedback">{errors.title.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="price">Price</label>
              <input
                type="number"
                className={`form-control ${errors.price ? "is-invalid" : ""}`}
                id="price"
                name="price"
                {...register("price", { required: "Price is required" })}
              />
              {errors.price && (
                <div className="invalid-feedback">{errors.price.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                className={`form-control ${errors.description ? "is-invalid" : ""}`}
                id="description"
                name="description"
                {...register("description", { required: "Description is required" })}
              />
              {errors.description && (
                <div className="invalid-feedback">{errors.description.message}</div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="category">SubCategory</label>
              <select
                className={`form-control ${
                  errors.category ? "is-invalid" : ""
                }`}
                id="category"
                name="category"
                {...register("category", { required: "Category is required" })}
              >
                <option value="">Select a Sub Category</option>
                {data?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              {errors.category && (
                <div className="invalid-feedback">
                  {errors.category.message}
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="imagefile">Image File</label>
              <input
                type="file"
                className={`form-control ${
                  errors.imagefile ? "is-invalid" : ""
                }`}
                id="imagefile"
                name="imagefile"
                {...register("imagefile", {
                  required: "Image file is required",
                })}
                accept="image/*"
              />
              {errors.imagefile && (
                <div className="invalid-feedback">
                  {errors.imagefile.message}
                </div>
              )}
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              onClick={handleCloseModal}
              type="button"
              className="btn btn-secondary text-small"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={disabled}
              className="btn btn-primary text-small"
            >
              {!disabled && "Add Menu Item"}
              {disabled && (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Please Wait Updating ...
                </>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddProduct;

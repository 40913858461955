import { Link, useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-white bg-white">
        <div className="sidebar-header text-center">
          <img
            src="logo.png"
            alt="noImage"
            className="app-logo "
            height={"40px"}
          />
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="nav navbar-nav ms-auto">
            <li className="nav-item dropdown">
              <div className="nav-dropdown">
                <Link
                  id="nav2"
                  className="nav-item nav-link dropdown-toggle text-secondary"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fas fa-user"></i> <span>Admin</span>{" "}
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fas fa-caret-down"
                  ></i>
                </Link>
                <div className="dropdown-menu dropdown-menu-end nav-link-menu">
                  <ul className="nav-list">
                    {/* <li>
                      <Link to="/profile" className="dropdown-item">
                        <i className="fas fa-address-card"></i> Profile
                      </Link>
                    </li>

                    <li>
                      <Link to="/settings" className="dropdown-item">
                        <i className="fas fa-cog"></i> Settings
                      </Link>
                    </li> */}
                    <div className="dropdown-divider"></div>
                    <li>
                      <button
                        onClick={handleLogout}
                        href=""
                        className="dropdown-item"
                      >
                        <i className="fas fa-sign-out-alt"></i> Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;

import { useMemo, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "pages/components/table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import SingleOrderSummary from "./components/single-order";
import UpdateLoanStatus from "./components/update-order";

const PendingOrders = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const controller = new AbortController();
 const [showModal, setShowModal] = useState(false);
 const [openModal, setOpenModal] = useState(false);
 const [modalData, setModalData] = useState({});
 const handleShowModal = (data) => {
   setShowModal(true);
   setModalData(data);
 };
 const handleCloseModal = () => {
   setShowModal(false);
   setModalData({});
 };

 const handleOpenModal = (data) => {
   setOpenModal(true);
   setModalData(data);
 };
 const handleHideModal = () => {
   setOpenModal(false);
   setModalData({});
 };

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ["orders"],
    queryFn: async () => {
      try {
        const response = await axiosPrivate.get("/admin/orders", {
          signal: controller.signal,
        });
        return response.data.data.orders;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo(
    () => [
      {
        id: "action", //access nested data with dot notation
        header: "Action",
        accessorFn: (row) => (
          <>
            <button
              className="btn btn-link btn-sm p-0"
              onClick={() => handleShowModal(row)}
            >
              <i className="text-success fa fa-eye"></i>
            </button>
            <button
              className="btn btn-link btn-sm p-0"
              onClick={() => handleOpenModal(row)}
            >
              <i className="text-primary fa fa-edit"></i>
            </button>
          </>
        ),
        size: 50,
      },
      {
        id: "number", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Order Number",
        accessorFn: (row) => row.number,
        size: 50,
      },
      {
        id: "price", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Total Cost",
        accessorFn: (row) => row.price.toLocaleString(),
        size: 50,
      },
      {
        id: "items", //access nested data with dot notation
        enableClickToCopy: true,
        header: "No.Of Items",
        accessorFn: (row) => row?.order_items?.length,
        size: 50,
      },
      {
        id: "status", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Order Status",
        accessorFn: (row) =>
          row?.status === "pending" ? (
            <div className="bg-warning p-1 text-small text-white rounded">
              Pending
            </div>
          ) : row?.status === "processing" ? (
            <div className="bg-secondary p-1 text-small text-white rounded">
              Processing
            </div>
          ) : row?.status === "delivery" ? (
            <div className="bg-primary p-1 text-small text-white rounded">
              On Delivery
            </div>
          ) : row?.status === "delivered" ? (
            <div className="bg-info p-1 text-small text-white rounded">
              Delivered
            </div>
          ) : row?.status === "completed" ? (
            <div className="bg-success p-1 text-small text-white rounded">
              Completed
            </div>
          ) : row?.status === "canceled" ? (
            <div className="bg-danger p-1 text-small text-white rounded">
              Cancelled
            </div>
          ) : (
            ""
          ),
        size: 50,
      },
      {
        id: "client", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Client",
        accessorFn: (row) => row?.client?.name,
        size: 50,
      },
      {
        id: "phone", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Contact",
        accessorFn: (row) => row?.client?.contact,
        size: 50,
      },
      {
        id: "address", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Address",
        accessorFn: (row) => row?.client?.address,
        size: 50,
      },
      {
        id: "email", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Client",
        accessorFn: (row) => row?.client?.email,
        size: 50,
      },

      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Timestamp",
        accessorFn: (row) => row.timestamp,
        size: 50,
      },
      {
        id: "onUpdate", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Last Updated",
        accessorFn: (row) => row.onUpdate,
        size: 50,
      },
    ],
    []
  );
  return (
    <>
      {" "}
      <SingleOrderSummary
        orderItems={modalData?.order_items}
        handleCloseModal={handleCloseModal}
        showModal={showModal}
      />
      <UpdateLoanStatus
        orderId={modalData?.id}
        orderStatus={modalData?.status}
        handleCloseModal={handleHideModal}
        showModal={openModal}
        refetch={refetch}
      />
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 page-header card p-3">
              <div className="page-pretitle">Orders</div>
              <h2 className="page-title">Pending Orders</h2>
            </div>
          </div>

          <div className="container row">
            <Table
              columns={columns}
              data={data?.filter((order) => (order.status === "pending")) ?? []}
              refetch={refetch}
              isLoading={isLoading}
              isRefetching={isRefetching}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PendingOrders;

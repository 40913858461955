import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "layouts/layout";
import Login from "pages/auth/login";
import Missing from "layouts/404";
import { Toaster } from "react-hot-toast";
import PersitLogin from "middlewares/global/persistentLogin";
import RequiredAuth from "middlewares/hooks/requiredLogin";
import AuthLayout from "layouts/authlayout";
import Dashboard from "pages/dashboard/dashboard";
import Clients from "pages/clients/clients";
import OrdersGeneral from "pages/orders/general-orders";
import PendingOrders from "pages/orders/pending-orders";
import ProcessingOrders from "pages/orders/processing-orders";
import DeliveryOrders from "pages/orders/delivery-orders";
import DeliveredOrders from "pages/orders/delivered-orders";
import CompletedOrders from "pages/orders/completed-orders";
import MenuCategory from "pages/menus/menu-category";
import MenuSubCategory from "pages/menus/menu-sub-category";
import ProductsItems from "pages/menus/product-items";
function App() {
  return (
    <>
      <Toaster />
      <Routes>
        <Route path="" element={<Layout />}>
          <Route path="/" element={<Login />} />

          <Route element={<PersitLogin />}>
            <Route element={<AuthLayout />}>
              <Route element={<RequiredAuth />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="clients" element={<Clients />} />
                <Route path="orders" element={<OrdersGeneral />} />
                <Route path="pending-orders" element={<PendingOrders />} />
                <Route
                  path="processing-orders"
                  element={<ProcessingOrders />}
                />
                <Route path="delivery-orders" element={<DeliveryOrders />} />
                <Route path="delivered-orders" element={<DeliveredOrders />} />
                <Route path="completed-orders" element={<CompletedOrders />} />
                <Route path="menu-categories" element={<MenuCategory />} />
                <Route path="menu-subcategories" element={<MenuSubCategory />} />
                <Route path="products" element={<ProductsItems />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Missing />} />
      </Routes>
    </>
  );
}

export default App;

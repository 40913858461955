import Navbar from "pages/components/navbar";
import Sidebar from "pages/components/sidebar";
import { Outlet } from "react-router-dom";
const AuthLayout = ({ children }) => {
  return (
    <>
      <div className="wrapper">
        <div id="body">
          <Navbar />
          <Sidebar />
          <div id="content-body">
            {children}
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;

import { Link, useNavigate } from "react-router-dom";

const Sidebar = () => {
const navigate = useNavigate();
const handleLogout = () => {
  localStorage.clear();
  navigate("/");
};
  return (
    <>
      <nav id="sidebar" className="mt-4">
        <ul className="list-unstyled components text-secondary">
          <li>
            <Link to="/dashboard">
              <i className="fas fa-home"></i> Dashboard
            </Link>
          </li>
          <li>
            <Link to="/clients">
              <i className="fas fa-users"></i> Clients
            </Link>
          </li>

          <li>
            <Link to="/orders">
              <i className="fas fa-chart-bar"></i> General Orders
            </Link>
          </li>

          <li>
            <Link to="/pending-orders">
              <i className="fas fa-clock"></i> Pending Orders
            </Link>
          </li>
          <li>
            <Link to="/processing-orders">
              <i className="fas fa-spinner"></i> Processing Orders
            </Link>
          </li>

          <li>
            <Link to="/delivery-orders">
              <i className="fas fa-shipping-fast"></i> Orders On Delivery
            </Link>
          </li>
          <li>
            <Link to="/delivered-orders">
              <i className="fas fa-truck"></i> Delivered Orders
            </Link>
          </li>
          <li>
            <Link to="/completed-orders">
              <i className="fas fa-check"></i> Completed Orders
            </Link>
          </li>
          <li>
            <Link to="/menu-categories">
              <i className="fas fa-bars"></i> Menu Categories
            </Link>
          </li>
          <li>
            <Link to="/menu-subcategories">
              <i className="fas fa-plus"></i> Sub Categories
            </Link>
          </li>
          <li>
            <Link to="/products">
              <i className="fas fa-bacon"></i>Food Products
            </Link>
          </li>
          {/* <li>
            <Link to="/users">
              <i className="fas fa-envelope"></i>Email Subs
            </Link>
          </li>
          <li>
            <Link to="/users">
              <i className="fas fa-user-friends"></i> Users
            </Link>
          </li> */}

          {/* <li>
            <Link to="/update-password">
              <i className="fas fa-user-lock"></i> Update password
            </Link>
          </li> */}
          
          {/* <li>
            <Link to="/settings">
              <i className="fas fa-cog"></i>Settings
            </Link>
          </li> */}
          <li className="mb-5">
            <button onClick={handleLogout} className="btn btn-sm btn-danger w-100">
              <i className="fas fa-sign-out-alt"></i> Logout
            </button>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;

import { MaterialReactTable } from "material-react-table";

const Table = ({
  columns,
  data,
  isLoading,
  refetch,
  headers,
  isRefetching,
}) => {
  return (
    <>
      <MaterialReactTable
        data={data}
        columns={columns}
        enableColumnFilterModes
        enableColumnOrdering
        enableRowSelection
        // manualPagination={true}
        enableGrouping
        enableStickyHeader
        className="table align-items-justify table-flush p-0 m-0"
        initialState={{
          density: "compact",
          showColumnFilters: false,
        }}
        paginationDisplayMode={"pages"}
        muiPaginationProps={{
          shape: "rounded",
          sx: { fontFamily: "Nunito", fontSize: "10px" },
        }}
        state={{ isLoading: isLoading, showProgressBars: isRefetching }}
        muiTableContainerProps={{ sx: { maxHeight: "500px" } }}
        muiTableHeadCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            fontFamily: "Nunito",
          },
        }}
        muiCircularProgressProps={{
          color: "secondary",
          thickness: 5,
          size: 25,
        }}
        muiSkeletonProps={{
          animation: "pulse",
          height: 28,
        }}
        muiTableBodyCellProps={{
          sx: {
            fontWeight: "normal",
            fontSize: "12px",
            fontFamily: "Nunito",
          },
        }}
        muiSearchTextFieldProps={{
          placeholder: "Search",
          sx: { fontFamily: "Nunito", fontSize: "12px" },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <>
            <div className="btn-group">
              <button
                onClick={refetch}
                className="btn btn-sm text-small btn-danger text-white rounded-0"
              >
                Refresh
              </button>
            </div>
            {headers}
          </>
        )}
      />
    </>
  );
};

export default Table;

import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { errorColor } from "services/colors/constants";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const AddMenu = ({
  handleCloseModal,
  showModal,
  refetch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const progress = new ProgressBar({ color: errorColor, size: 4 });
  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
const onSubmit = async (formData) => {
  try {
    progress.start();
    setDisabled(true);

    // Initialize FormData object
    const submissionData = new FormData();
     const dataObject = { title: formData.title };
     submissionData.append("data", JSON.stringify(dataObject));
    // Append each form value to the FormData object
    for (const [key, value] of Object.entries(formData)) {
      // For file inputs, you need to append the file object itself
      if (key === "imagefile") {
        // Assuming here that register name and file input name is 'imagefile' and it holds the file
        submissionData.append(key, value[0]); // Since it's a file, access the first item
      } else {
        submissionData.append(key, value);
      }
    }

    // Make the HTTP request using axios
    const response = await axiosPrivate.post(
      "/admin/menus/menu-categories",
      submissionData,
      {
        headers: {
          // Indicate that the request body is FormData
          "Content-Type": "multipart/form-data",
        },
      }
    );

    // Success handling
    toast.success(response?.data?.messages);
    progress.finish();
    handleCloseModal();
    setDisabled(false);
    reset(); // Reset the form using react-hook-form's reset method
    refetch(); // Assuming you want to refetch some data after submission
  } catch (error) {
    progress.finish();
    setDisabled(false);
    console.log(error);
    toast.error("An error occurred");
    if (error?.response?.status === 401) {
      navigate("/", {
        state: { from: location },
        replace: true,
      });
    }
  }
};

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h6>Add Menu Category</h6>
          <button
            onClick={handleCloseModal}
            className="btn-close btn-danger btn"
          ></button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                id="title"
                name="title"
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <div className="invalid-feedback">{errors.title.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="imagefile">Image File</label>
              <input
                type="file"
                className={`form-control ${
                  errors.imagefile ? "is-invalid" : ""
                }`}
                id="imagefile"
                name="imagefile"
                {...register("imagefile", {
                  required: "Image file is required",
                })}
                accept="image/*"
              />
              {errors.imagefile && (
                <div className="invalid-feedback">
                  {errors.imagefile.message}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleCloseModal}
              type="button"
              className="btn btn-secondary text-small"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={disabled}
              className="btn btn-primary text-small"
            >
              {!disabled && "Add Menu Category"}
              {disabled && (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Please Wait Updating ...
                </>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AddMenu;

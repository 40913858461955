import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { errorColor } from "services/colors/constants";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const EditItemMenuModal = ({ handleCloseModal, showModal, refetch, modalData }) => {
  // console.log(modalData);
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const progress = new ProgressBar({ color: errorColor, size: 4 });
  const axiosPrivate = useAxiosPrivate();
  const [data, setData] = useState([]);
    const controller = new AbortController();
    const getCategories = async () => {
      try {
        const response = await axiosPrivate.get(
          "/admin/menus/sub-menu-categories",
          {
            signal: controller.signal,
          }
        );
        setData(response.data.data.menu);
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
      }
    };
    useEffect(() => {
      getCategories();
      // eslint-disable-next-line
    }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: modalData.title,
      categoryId: modalData.subcategoryId,
      price: modalData.price,
      description: modalData.description,
    },
  });
  useEffect(() => {
    if (modalData) {
      reset({
        title: modalData.title,
        categoryId: modalData.subcategoryId,
        price: modalData.price,
        description: modalData.description,
      });
    }
  }, [modalData, reset]);
  const onSubmit = async (data) => {
    // console.log(data);
    data.itemId = modalData.id;
    try {
      progress.start();
      setDisabled(true);

      // Make the HTTP request using axios
      const response = await axiosPrivate.put("/admin/items/items", data);

      // Success handling
      toast.success(response?.data?.messages);
      progress.finish();
      handleCloseModal();
      setDisabled(false);
      reset(); 
      refetch();
    } catch (error) {
      progress.finish();
      setDisabled(false);
      console.log(error);
      toast.error("An error occurred");
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h6>Edit Menu Item</h6>
          <button
            onClick={handleCloseModal}
            className="btn-close btn-danger btn"
          ></button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                id="title"
                name="title"
                {...register("title", { required: "Title is required" })}
              />
              {errors.title && (
                <div className="invalid-feedback">{errors.title.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="price">Price</label>
              <input
                type="number"
                className={`form-control ${errors.price ? "is-invalid" : ""}`}
                id="price"
                name="price"
                {...register("price", { required: "Price is required" })}
              />
              {errors.price && (
                <div className="invalid-feedback">{errors.price.message}</div>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="description">Description</label>
              <input
                type="text"
                className={`form-control ${
                  errors.description ? "is-invalid" : ""
                }`}
                id="description"
                name="description"
                {...register("description", {
                  required: "Description is required",
                })}
              />
              {errors.description && (
                <div className="invalid-feedback">
                  {errors.description.message}
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="category">SubCategory</label>
              <select
                className={`form-control ${
                  errors.categoryId ? "is-invalid" : ""
                }`}
                id="categoryId"
                name="categoryId"
                {...register("categoryId", { required: "Category is required" })}
              >
                <option value="">Select a Sub Category</option>
                {data?.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.title}
                  </option>
                ))}
              </select>
              {errors.categoryId && (
                <div className="invalid-feedback">
                  {errors.categoryId.message}
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleCloseModal}
              type="button"
              className="btn btn-secondary text-small"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={disabled}
              className="btn btn-primary text-small"
            >
              {!disabled && "Update Menu Item"}
              {disabled && (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Please Wait Updating ...
                </>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default EditItemMenuModal;

import { useMemo } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "pages/components/table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

const Clients = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const controller = new AbortController();

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ["clients"],
    queryFn: async () => {
      try {
        const response = await axiosPrivate.get("/admin/clients", {
          signal: controller.signal,
        });
        return response.data.data.clients;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo(
    () => [
      {
        id: "name", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Name",
        accessorFn: (row) => row.name,
        size: 50,
      },
      {
        id: "contact", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Contact",
        accessorFn: (row) => row.contact,
        size: 50,
      },
      {
        id: "email", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Email",
        accessorFn: (row) => row.email,
        size: 50,
      },
      {
        id: "address", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Address",
        accessorFn: (row) => row.address,
        size: 50,
      },
      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Timestamp",
        accessorFn: (row) => row.timestamp,
        size: 50,
      },
      {
        id: "onUpdate", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Last Updated",
        accessorFn: (row) => row.onUpdate,
        size: 50,
      },
    ],
    []
  );
  return (
    <>
      {" "}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-12 page-header card p-3">
              <div className="page-pretitle">Overview</div>
              <h2 className="page-title">Clients</h2>
            </div>
          </div>

          <div className="container row">
            <Table
              columns={columns}
              data={data ?? []}
              refetch={refetch}
              isLoading={isLoading}
              isRefetching={isRefetching}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;

import { Modal } from "react-bootstrap";
import toast from "react-hot-toast";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { errorColor } from "services/colors/constants";
import { useNavigate, useLocation } from "react-router-dom";
import ProgressBar from "@badrap/bar-of-progress";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
const UpdateLoanStatus = ({
  handleCloseModal,
  showModal,
  orderId,
  orderStatus,
  refetch,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const progress = new ProgressBar({ color: errorColor, size: 4 });
  const axiosPrivate = useAxiosPrivate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onUpdate = async (data) => {
    try {
      progress.start();
      setDisabled(true);
      const response = await axiosPrivate.put("/admin/orders", data);
      toast.success(response?.data?.messages);
      progress.finish();
      handleCloseModal();
      setDisabled(false);
      reset();
      refetch();
    } catch (error) {
      setDisabled(false);
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        backdrop="static"
        keyboard={false}
        onHide={handleCloseModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h6>Update Order Status</h6>
          <button
            onClick={handleCloseModal}
            className="btn-close btn-danger btn"
          ></button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onUpdate)}>
          <Modal.Body>
            <div className="mb-3">
              <label htmlFor="status" className="form-label">
                Status
              </label>
              <select
                className="form-select mb-3"
                id="status"
                defaultValue={orderStatus}
                {...register("status", {
                  required: true,
                })}
              >
                <option value="pending">Pending</option>
                <option value="processing">Processing</option>
                <option value="delivery">Delivery</option>
                <option value="delivered">Delivered</option>
                <option value="completed">Completed</option>
                <option value="canceled">Cancell</option>
              </select>
              {errors?.status?.type === "required" && (
                <p className="text-small m-0 text-danger help-block">
                  A Status Is Required
                </p>
              )}
              <input
                type="hidden"
                defaultValue={orderId}
                {...register("orderId", {
                  required: true,
                })}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleCloseModal}
              type="button"
              className="btn btn-secondary text-small"
            >
              Close
            </button>
            <button
              type="submit"
              disabled={disabled}
              className="btn btn-primary text-small"
            >
              {!disabled && "Update Status"}{" "}
              {disabled && (
                <>
                  {" "}
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>{" "}
                  Please Wait Updating ...
                </>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default UpdateLoanStatus;

import { useMemo, useState } from "react";
import useAxiosPrivate from "middlewares/hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";
import Table from "pages/components/table";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import AddProduct from "./components/add-product";
import EditItemMenuModal from "./components/products/edit-menu-item";
import UpdateModalImageProductItem from "./components/products/update-image";
const ProductsItems = () => {
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();
  const controller = new AbortController();
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [modalData, setModalData] = useState([]);

  const [openModalImage, setOpenModalImage] = useState(false);
  const handleOpenModalImage = (data) => {
    setOpenModalImage(true);
    setModalData(data);
  };
  const handleCloseModalImage = () => {
    setOpenModalImage(false);
    setModalData([]);
  };

  const [openModalData, setOpenModalData] = useState(false);
  const handleOpenModalData = (data) => {
    setOpenModalData(true);
    setModalData(data);
  };
  const handleCloseModalData = () => {
    setOpenModalData(false);
    setModalData([]);
  };

  const onDelete = async (data) => {
    try {
      // Make the HTTP request using axios
      const response = await axiosPrivate.delete("/admin/items/items", {
        data,
        headers: { "Content-Type": "application/json" },
      });
      // Success handling
      toast.success(response?.data?.messages);
      refetch();
    } catch (error) {
      console.log(error);
      toast.error("An error occurred");
      if (error?.response?.status === 401) {
        navigate("/", {
          state: { from: location },
          replace: true,
        });
      }
    }
  };

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ["food-items"],
    queryFn: async () => {
      try {
        const response = await axiosPrivate.get("/admin/items/items", {
          signal: controller.signal,
        });
        return response.data.data.food;
      } catch (error) {
        if (error?.response?.status === 401) {
          navigate("/", {
            state: { from: location },
            replace: true,
          });
        }
      }
    },
    placeholderData: keepPreviousData,
  });

  const columns = useMemo(
    () => [
      {
        id: "action", //access nested data with dot notation
        header: "Action",
        accessorFn: (row) => (
          <>
            <button
              className="btn btn-link btn-sm p-0"
              onClick={() => handleOpenModalImage(row)}
            >
              <i className="text-success fa fa-receipt"></i>
            </button>
            <button
              className="btn btn-link btn-sm p-0"
              onClick={() => handleOpenModalData(row)}
            >
              <i className="text-primary fa fa-edit"></i>
            </button>
            <button
              className="btn btn-link btn-sm p-0"
              onClick={() => {
                const userConfirmed = window.confirm(
                  "Are you sure you want to delete this?"
                );
                if (userConfirmed) {
                  onDelete({ itemId: row.id });
                  alert("You have Deleted the Item!");
                } else {
                  // User clicked "Cancel"
                  alert("You Have Cancelled the Operation");
                }
              }}
            >
              <i className="text-danger fa fa-trash"></i>
            </button>
          </>
        ),
        size: 50,
      },
      {
        id: "title", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Title",
        accessorFn: (row) => row.title,
        size: 150,
      },
      {
        id: "subcategory", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Subcatgeory",
        accessorFn: (row) => row.subcategory,
        size: 150,
      },
      {
        id: "category", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Catgeory",
        accessorFn: (row) => row.category,
        size: 150,
      },
      {
        id: "image", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Image",
        accessorFn: (row) => (
          <img src={row.image} height="50px" alt={row.title} />
        ),
        size: 50,
      },

      {
        id: "price", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Price",
        accessorFn: (row) => row.price.toLocaleString(),
        size: 50,
      },

      {
        id: "description", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Description",
        accessorFn: (row) => row.description,
        size: 50,
      },

      {
        id: "timestamp", //access nested data with dot notation
        enableClickToCopy: true,
        header: "Timestamp",
        accessorFn: (row) => row.timestamp,
        size: 50,
      },
    ],
    // eslint-disable-next-line
    []
  );
  return (
    <>
      <AddProduct
        handleCloseModal={handleCloseModal}
        showModal={openModal}
        refetch={refetch}
      />
      <UpdateModalImageProductItem
        handleCloseModal={handleCloseModalImage}
        showModal={openModalImage}
        refetch={refetch}
        modalData={modalData}
      />
      <EditItemMenuModal
        handleCloseModal={handleCloseModalData}
        showModal={openModalData}
        refetch={refetch}
        modalData={modalData}
      />
      <div className="content">
        <div className="container">
          <div className="card">
            <div className="row">
              <div className="col-md-6 page-header p-3">
                <div className="page-pretitle mx-3">Overview</div>
                <h2 className="page-title mx-3">Menu Food Items</h2>
              </div>
              <div className="col-md-6 page-header p-3">
                <button className="btn btn-success" onClick={handleOpenModal}>
                  Add Food Item
                </button>
              </div>
            </div>
          </div>

          <div className="container row">
            <Table
              columns={columns}
              data={data ?? []}
              refetch={refetch}
              isLoading={isLoading}
              isRefetching={isRefetching}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductsItems;
